/* eslint-disable space-before-function-paren */
/* eslint-disable no-throw-literal */
/* eslint-disable camelcase */
import axios, { CancelTokenSource } from 'axios';
import { API_CONFIG, elasticInfo } from '~/config/constants';
import { API_METHOD, API_DATA, CONTENT_TYPE } from '~/types';
import { store } from '~/redux/store';
import { AuthenticationState, authUser } from '~/redux/modules/authentication';
import { onRefreshToken } from '~/api/authentication';
import { jwtDecode } from '~/utils/helpers';
import Storage from '~/utils/Storage';
import { isObject } from 'lodash';

const { API_URL, API_TIME_OUT, API_KEY } = API_CONFIG;
const APP_JSON = 'application/json';
const APP_PDF = 'application/pdf';

let source: CancelTokenSource;
let currentEndpoint = '';

let startTime = 0;

type Request = {
    url: string,
    method: API_METHOD,
    data?: API_DATA,
    headers: {
        Authorization?: string,
        'Content-Type': string,
        Accept: string,
        responseType?: string,
        secretKey?: string,
        key?: string, // for captcha requests
        secretToken?: string // for elastic webhook
    },
    onUploadProgress?: (progressEvent: any) => void
};

type API_TYPE = {
    endpoint: string,
    method?: API_METHOD,
    data?: API_DATA,
    token?: string,
    unAuth?: boolean,
    isHeaderSecretKeys?: boolean,
    isSerialized?: boolean,
    contentType?: CONTENT_TYPE,
    onUploadProgress?: (progressEvent: any) => void
}

const API = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': APP_JSON,
        secretKey: API_KEY!
    },
    timeout: API_TIME_OUT
});

const ELASTIC_API = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': APP_JSON,
        key: elasticInfo.key!
    },
    timeout: API_TIME_OUT
});

const CAPTCHA_API = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': APP_JSON,
        secretToken: process.env.REACT_APP_GOOGLE_RECAPTCHA_SECRET_KEY!
    },
    timeout: API_TIME_OUT
});

// API.interceptors.request.use(async function (config: { cancelToken: any; }) {
//     source = axios.CancelToken.source();

//     config.cancelToken = source.token; // it will update cancelToken on every call

//     return config;
// });

API.interceptors.response.use(function (response: any) {
    // const endTime = new Date().getTime();

    // console.log('endTime:', new Date().getTime());
    // console.log('total:', endTime - startTime);
    // console.log('--------------------------------');
    return response;
}, function (error: any) {
    return Promise.reject(error);
});

API.interceptors.response.use((response: any) => response, (error: { status?: any; message?: any; response?: any; config?: any; }) => {
    const { response, config } = error;
    const data = response?.data?.status;
    const apiError = JSON.stringify(data || error);

    Storage.setItem('apiError', apiError);

    if (error.status !== 401 && apiError !== '"Signature has expired"') {
        if (data) {
            return Promise.reject(data);
        }

        return Promise.reject(error?.message === 'timeout of 15000ms exceeded' ? new Error('Network Error') : error);
    }

    return REFRESH_TOKEN(config).then(resp => {
        return resp;
    }).catch((err) => {
        return Promise.reject(err);
    });
});

const serialize = (data: Object) => {
    return Object.keys(data)
        .map((k, i) => {
            const value = encodeURIComponent(Object.values(data)[i]);
            const key = encodeURIComponent(k);

            return `${key}=${value || ''}`;
        })
        .join('&');
};

export default async function API_REQUEST(
    {
        endpoint,
        method = 'GET',
        data,
        token,
        unAuth,
        isHeaderSecretKeys,
        isSerialized,
        contentType = APP_JSON,
        onUploadProgress
    }: API_TYPE) {
    currentEndpoint = endpoint;
    const { userToken }: AuthenticationState = store.getState().authentication;

    const isConvertToPdf = endpoint.includes('convert-to-pdf');
    const tokenType = 'Bearer';
    const Authorization = unAuth ? '' : `${tokenType} ${token || userToken}`;

    const formattedData = data ? (isSerialized ? serialize(data) : data) : '';

    const request: Request = {
        url: endpoint,
        method,
        data: formattedData,
        headers: {
            Authorization,
            'Content-Type': contentType,
            Accept: APP_JSON,
            secretKey: API_KEY
        },
        onUploadProgress
    };

    startTime = new Date().getTime();
    // console.log('--------------------------------');
    // console.log('endpoint:', endpoint);
    // console.log('startTime:', startTime);

    if (method === 'GET') delete request.data;

    if (unAuth) delete request.headers.Authorization;

    if (isHeaderSecretKeys) {
        delete request.headers.secretKey;
        endpoint.concat(`?secretKey${API_KEY}`);
    }

    if (isConvertToPdf) {
        request.headers = {
            ...request.headers,
            Accept: APP_PDF,
            responseType: 'blob'
        };
    }

    let requestData: any;

    if (isObject(request.data)) {
        requestData = { ...request.data };

        if ('image' in requestData) {
            delete requestData.image;
        }

        if ('feature_image' in requestData) {
            delete requestData.feature_image;
        }

        if ('coverPhoto' in requestData) {
            delete requestData.coverPhoto;
        }

        if ('htmlContent' in requestData) {
            delete requestData.requestData;
        }

        if ('about' in requestData) {
            delete requestData.about;
        }

        if ('purpose' in requestData) {
            delete requestData.purpose;
        }
    }

    try {
        Storage.setItem('apiRequest', JSON.stringify({
            ...request,
            data: requestData || request.data
        }));
    } catch {
        Storage.setItem('apiRequest', 'Unable to set request data in local storage');
    }

    const response = await API(request);

    if (response.status === 200) {
        return response.data;
    } else {
        const error = new Error(response.statusText);

        throw { ...error, response };
    }
}

export async function ELASTIC_API_REQUEST(
    {
        endpoint,
        method = 'GET',
        data,
        token,
        unAuth,
        isHeaderSecretKeys,
        isSerialized,
        contentType = APP_JSON
    }: API_TYPE) {
    currentEndpoint = endpoint;
    const { userToken }: AuthenticationState = store.getState().authentication;

    console.log('userToken', userToken);

    const tokenType = 'Bearer';
    const Authorization = unAuth ? '' : `${tokenType} ${token || userToken}`;

    const formattedData = data ? (isSerialized ? serialize(data) : data) : '';

    const request: Request = {
        url: endpoint,
        method,
        data: formattedData,
        headers: {
            Authorization,
            'Content-Type': contentType,
            Accept: APP_JSON,
            secretKey: API_KEY,
            key: elasticInfo.key
        }
    };

    startTime = new Date().getTime();

    if (method === 'GET') delete request.data;

    if (unAuth) delete request.headers.Authorization;

    Storage.setItem('apiRequest', JSON.stringify(request));

    console.log('request', request);

    const response = await ELASTIC_API(request);

    if (response.status === 200) {
        return response.data;
    } else {
        const error = new Error(response.statusText);

        throw { ...error, response };
    }
}

export async function CAPTCHA_API_REQUEST(
    {
        endpoint,
        method = 'GET',
        data,
        token,
        unAuth,
        isHeaderSecretKeys,
        isSerialized,
        contentType = APP_JSON
    }: API_TYPE) {
    currentEndpoint = endpoint;
    const { userToken }: AuthenticationState = store.getState().authentication;

    console.log('userToken', userToken);

    const isConvertToPdf = endpoint.includes('convert-to-pdf');
    const tokenType = 'Bearer';
    const Authorization = unAuth ? '' : `${tokenType} ${token || userToken}`;

    const formattedData = data ? (isSerialized ? serialize(data) : data) : '';

    const request: Request = {
        url: endpoint,
        method,
        data: formattedData,
        headers: {
            Authorization,
            'Content-Type': contentType,
            Accept: APP_JSON,
            secretKey: API_KEY,
            secretToken: process.env.REACT_APP_GOOGLE_RECAPTCHA_SECRET_KEY
        }
    };

    // startTime = new Date().getTime();

    if (method === 'GET') delete request.data;

    if (unAuth) delete request.headers.Authorization;

    if (isConvertToPdf) {
        request.headers = {
            ...request.headers,
            Accept: APP_PDF,
            secretKey: API_KEY,
            responseType: 'blob'
        };
    }

    Storage.setItem('apiRequest', JSON.stringify(request));

    console.log('request', request);

    const response = await CAPTCHA_API(request);

    if (response.status === 200) {
        return response.data;
    } else {
        const error = new Error(response.statusText);

        throw { ...error, response };
    }
}

export async function REFRESH_TOKEN(config: any) {
    const { refreshToken }: AuthenticationState = store.getState().authentication;

    if (refreshToken) {
        try {
            const { token, refresh_token } = await onRefreshToken(refreshToken);

            config.headers.Authorization = `Bearer ${token}`;

            store.dispatch(authUser({
                userToken: token,
                refreshToken: refresh_token,
                expiration: (jwtDecode(token)?.exp || 0) * 1000,
                isAuthed: true
            }));

            return await API(config);
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export async function EXTERNAL_API_REQUEST(
    apiUrl: string,
    endpoint: string,
    data?: any,
    headers?: any,
    method?: API_METHOD,
    isJsonForm?: boolean
) {
    const url = `${apiUrl}${endpoint}${data && !isJsonForm ? `?${serialize(data)}` : ''}`;

    const response = await fetch(url, {
        method: method || 'GET',
        headers: headers || {},
        body: data
    });

    if (response.ok) {
        const data = await response.json();

        return data;
    } else {
        const error = new Error(response.statusText);

        throw { ...error, response };
    }
}

export const onCancelRequest = () => {
    if (source && !currentEndpoint?.includes('notifications') && !currentEndpoint?.includes('push-token') && !currentEndpoint?.includes('create-user') && !currentEndpoint?.includes('chat/members') && !currentEndpoint?.includes('my-group-info') && !currentEndpoint?.includes('subgroup-info')) {
        source.cancel('Unmounted');
    }
};
